.market-flag {
    margin-left: 2px;
    cursor: pointer;
}

.highlighted {
    background: rgba(255, 230, 0, 0.5);
    padding: 3px 5px;
    line-height: 1.7;
    border-radius: 3px;
}