.edit-modal {
    display: block;
    max-width: 80%;
}

.marketLabel {
    font-size: medium;
    margin-right: 1%;
}

.modal-body > div:last-child > hr{
    display: none;
}
