.form-control-borderless {
    border: none;
}

.form-control-borderless:hover,
.form-control-borderless:active,
.form-control-borderless:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.smaller-card {
    padding: 0.5rem;
    padding-left: 1rem
}