textarea {
    width: 100%;
    resize: none;
    overflow: hidden;
    border: none;
}

.btn-ft {
    color: #fff;
    background-color: #30add1;
    border-color: #30add1;
}

.btn-ft:hover {
    background-color: #1e6b80;
    border-color: #1e6b80;
}

.btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #30add1;
    background-color: #30add1;
}

.form-control:focus, .custom-select:focus {
    border-color: #30add1;
    box-shadow: 0 1px 1px #30add1 inset, 0 0 4px #30add1;
}

.text-primary {
    color: #30add1 !important;
}

select:focus option:checked:hover, select:focus option:checked:hover {
    background-color: #30add1 !important;
    color: #000;
}

a {
    color: #30add1;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #1e6b80;
}

.page-link {
    color: #30add1;
    outline: none !important;
    box-shadow: none !important;
}

.page-item.active .page-link {
    background-color: #1e6b80;
    border-color: #1e6b80;
}

.hidden {
    display: none;
}

.cursor-pointer{
    cursor:pointer;
}

.highlighted {
    background: rgba(255, 230, 0, 0.5);
    padding: 3px 5px;
    line-height: 1.7;
    border-radius: 3px;
}