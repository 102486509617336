body {
    margin: 0;
    font-size: .875rem;
    font-weight: 350;
    line-height: 1.6;
    color: #333;
    text-align: left;
    background-color: #fff;
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.content {
    width: 80%;
    height: 100%;
}